import React from 'react';
import styled from "styled-components";
import Header from "../components/header";
import VacanciesComponent from "../components/vacanciesComponent";
import Footer from "../components/footer";
import Decoration from "../images/decoration.svg"
import "../index.css"
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../components/seo";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 100px;
  width: 1110px;
  position: relative;
  align-self: center;
  flex: 1 1 auto
`;

const ReturnImages = () => {
    return (
        <>
            <Image src={Decoration} alt="decoration"/>
        </>
    )
}

const Image = styled.img`
  position: absolute;
  left: -460px;
  top: 270px;
  z-index: -1;
  @media screen and (max-width: 1280px) {
    display: none;
  }
`;

const Vacancies = ({vacancies}) => {
    const data = useStaticQuery(query);
    return (
            <MainContainer>
                <Seo title={data.allStrapiVacanciesSeos.nodes[0].seo_title}
                     description={data.allStrapiVacanciesSeos.nodes[0].seo_description}
                     keywords={data.allStrapiVacanciesSeos.nodes[0].seo_keywords.split(',')}
                />
                <Container>
                    <ReturnImages/>
                    <div style={{alignSelf: "center"}}>
                        <Header/>
                    </div>
                    <VacanciesComponent vacancies={vacancies}/>
                </Container>
                <Footer/>
            </MainContainer>
    )
}

const query = graphql`
query{
  allStrapiVacanciesSeos {
    nodes {
      seo_title
      seo_description
      seo_keywords
    }
  }
}
`;

export default Vacancies;