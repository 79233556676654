import React from 'react';
import styled from "styled-components";
import {graphql, Link, useStaticQuery} from "gatsby";
import RightArrow from "../images/rightArrow.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1110px;
  align-self: center;
  margin-bottom: ${props => props.type === "main" ? "251px" : "0"};
  @media screen and (max-width: 1100px) {
    margin-bottom: ${props => props.type === "main" ? "211px" : "0"};
    width: 1000px;
  }
  @media screen and (max-width: 960px) {
    margin-bottom: ${props => props.type === "main" ? "161px" : "0"};
    width: 850px;
  }
  @media screen and (max-width: 800px) {
    margin-bottom: ${props => props.type === "main" ? "111px" : "0"};
    width: 700px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: ${props => props.type === "main" ? "700" : "800"};
  font-size: ${props => props.type === "main" ? "24px" : "30px"};
  color: #4C77FF;
  margin-bottom: ${props => props.type === "main" ? "70px" : "46px"};
  @media screen and (max-width: 1100px) {
    font-size: ${props => props.type === "main" ? "20px" : "26px"};
    margin-bottom: ${props => props.type === "main" ? "50px" : "36px"};
  }
  @media screen and (max-width: 960px) {
    font-size: ${props => props.type === "main" ? "16px" : "22px"};
    margin-bottom: ${props => props.type === "main" ? "45px" : "26px"};
  }
  @media screen and (max-width: 800px) {
    font-size: ${props => props.type === "main" ? "12px" : "26px"};
    margin-bottom: ${props => props.type === "main" ? "40px" : "16px"};
  }
`;

const Subtitle = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 24px;
  color: #103072;
  margin-bottom: 30px;
`;

const Text = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  color: #212939;
  line-height: 22px;
  @media screen and (max-width: 1100px) {
    font-size: 13px;
    line-height: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
  }
`;

const TextLink = styled(Link)`
  font-family: Montserrat;
  font-size: 16px;
  color: #212939;
  line-height: 22px;
  @media screen and (max-width: 1100px) {
    font-size: 13px;
    line-height: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RowContainerWithMargin = styled(RowContainer)`
  margin-bottom: 24px;
  @media screen and (max-width: 1100px) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 960px) {
    margin-bottom: 17px;
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 15px;
  }
`;

const Divider = styled.div`
  border: 1px solid #E4E4E4;
  margin-bottom: 24px;
`;

const OwnLink = styled(Link)`
  text-decoration: none
`;

const Image = styled.img`
  @media screen and (max-width: 1100px) {
    width: 38px;
    margin-top: -1px;
  }
  @media screen and (max-width: 960px) {
    width: 35px;
    margin-top: -3px;
  }
  @media screen and (max-width: 800px) {
    width: 32px;
    margin-top: -5px;
  }
`;

const VacanciesComponent = ({type}) => {
    const data = useStaticQuery(query);
    const dataForMainPage = [];
    for (let i = 0; i< 3; i++){
        dataForMainPage[i] = data.allStrapiVacancies.edges[data.allStrapiVacancies.edges.length - i - 1]
    }

    return (
        <Container type={type}>
            <RowContainer>
                <Title type={type}>
                    Вакансии
                </Title>
                {type === "main" ? <TextLink to="/vacancies">
                        Подробнее
                    </TextLink>
                    : ""}
            </RowContainer>
            {type !== "main" ? <Subtitle>
                Сейчас мы ищем
            </Subtitle> : ""}
            {type === "main"
                ? dataForMainPage.map((item, index) => (
                        <>
                            <RowContainerWithMargin>
                                <Text>
                                    {item.node.title}
                                </Text>
                                <OwnLink to={`/vacancies/${index+1}`}>
                                    <RowContainer>
                                        <Text style={{color: "#003DB6", marginRight: "13px"}}>
                                            Посмотреть вакансию
                                        </Text>
                                        <Image src={RightArrow} alt="arrow"/>
                                    </RowContainer>
                                </OwnLink>
                            </RowContainerWithMargin>
                            <Divider/>
                        </>
                    ))
                : data.allStrapiVacancies.edges.map((item, index) => (
                    <>
                        <RowContainerWithMargin>
                            <Text>
                                {item.node.title}
                            </Text>
                            <OwnLink to={`/vacancies/${index+1}`}>
                                <RowContainer>
                                    <Text style={{color: "#003DB6", marginRight: "13px"}}>
                                        Посмотреть вакансию
                                    </Text>
                                    <Image src={RightArrow} alt="arrow"/>
                                </RowContainer>
                            </OwnLink>
                        </RowContainerWithMargin>
                        <Divider/>
                    </>
                ))}
        </Container>
    )
}

const query = graphql`
query{
  allStrapiVacancies(sort: {fields: created_at}) {
    edges {
      node {
        title
        conditions
        requirements
        responsibilities
        strapiId
      }
    }
  }
}
`;

export default VacanciesComponent;